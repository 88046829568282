export let sceneController = null;
export const setSceneController = (controller) => {
    sceneController = controller;

    return sceneController;
};

export let modelController = null;
export const setModelController = (controller) => {
    modelController = controller;

    return modelController;
};

export let settingManager = null;
export const setSettingManager = (manager) => {
    settingManager = manager;

    return settingManager;
};

export let componentManager = null;
export const setComponentManager = (manager) => {
    componentManager = manager;

    return componentManager;
};

export let vizframe = null;
export const setVizframe = (engine) => {
    vizframe = engine;

    return vizframe;
};
