import { createTheme, responsiveFontSizes } from '@mui/material/styles';

const mainColor = '#19588f';
const secondaryColor = '#003666';
const inactiveColor = '#282828';

const theme = createTheme({
    spacing: 10,
    typography: {
        fontFamily: 'EncodeSans',
        allVariants: {
            color: secondaryColor
        },
        h1: {
            fontWeight: 700,
            fontSize: 27,
            textTransform: 'none',
            fontVariant: 'small-caps'
        },
        h2: {
            fontWeight: 700,
            fontSize: 22,
            lineHeight: 1.5,
            textTransform: 'none',
            fontVariant: 'small-caps',
            letterSpacing: '0.5px'
        },
        h3: {
            fontSize: 20
        },
        h4: {
            fontSize: 18,
            fontWeight: 500,
            textTransform: 'none',
            fontVariant: 'small-caps'
        },
        h5: {
            fontSize: 16
        },
        h6: {
            fontSize: 16
        },
        body1: {
            fontWeight: 300,
            fontSize: 16
        },
        body2: {
            fontWeight: 300,
            textTransform: 'none',
            fontVariant: 'small-caps',
            fontSize: 16
        },
        caption: {
            fontWeight: 300,
            textTransform: 'none',
            fontVariant: 'small-caps',
            fontSize: 13
        }
    },
    shape: {
        borderRadius: 10
    },
    palette: {
        primary: {
            main: mainColor,
            contrastText: '#ffffff'
        },
        secondary: {
            main: secondaryColor,
            contrastText: '#ffffff'
        },
        inactive: {
            main: inactiveColor,
            contrastText: '#ffffff'
        }
    }
});

export default responsiveFontSizes(theme);
