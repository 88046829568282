import { proxy, useSnapshot } from 'valtio';
import { subscribeKey } from 'valtio/utils';

class GlobalState {
    constructor() {
        this.data = proxy({
            style: {},
            rotate: 0,
            scale: 1,
            rotation: 0,
            ready: null,
            isMobile: null,
            presetId: null,
            layerLevel: 0,
            presetColor: {},
            fullscreen: null,
            componentId: null,
            annotation: true,
            annotationIndex: null,
            editAnnotation: null
        });
    }

    get snapshot() {
        return useSnapshot(this.data);
    }

    subscribeVariable(callback, key) {
        return this.data[key] !== undefined
            ? subscribeKey(this.data, key, callback)
            : null;
    }
}

const globalState = new GlobalState();
export default globalState;
