import React from 'react';
import { CircularProgress } from '@mui/material';

function LazyFallback() {
    return (
        <div className='loading'>
            <CircularProgress align='center' color='primary' />
        </div>
    );
}

export default LazyFallback;
