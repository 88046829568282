import React, { useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import ReactGA from 'react-ga4';
import SuspenseVF from '../element/SuspenseVF';
import globalState from '../global/state';
import theme from '../theme';

import LIB from '../helper/lib';
import META_INFO from '../data/version.json';

function Initialize({ children }) {
    const checkScreen = () => {
        globalState.data.isMobile = window.innerWidth < 900;
    };

    useEffect(() => {
        checkScreen();
        window.addEventListener('resize', checkScreen);

        if (process.env.GA) {
            ReactGA.initialize(process.env.GA);
            document.addEventListener('click', ButtonPressed);
        }

        return () => {
            window.removeEventListener('resize', checkScreen);
            if (process.env.GA) {
                document.removeEventListener('click', ButtonPressed);
            }
        };
    });

    const ButtonPressed = (e) => {
        if (!e || !e.target || !process.env.GA) return;

        const category = e.target.getAttribute('mo');
        const action = e.target.getAttribute('vfstat_action');

        LIB.recordAnalytics(category, action);
    };

    if (process.env.GA) {
        ReactGA.initialize(process.env.GA);
        document.addEventListener('click', ButtonPressed, { passive: true });
    }

    if (META_INFO.version) {
        window.vfVersion = META_INFO.version;
    }

    if (META_INFO.timestamp) {
        window.vfTimestamp = META_INFO.timestamp;
    }

    return (
        <SuspenseVF>
            <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </SuspenseVF>
    );
}

export default Initialize;
