const IMAGE_QUALITY = 0.85;
const DEFAULT_IMAGE = '../asset/model/custom_AO.png';

const TYPES = {
    image: 'Image',
    text: 'Text'
};

const DEFAULT = {
    scale: 1,
    rotation: 0,
    hsv: [0, 50, 50],
    type: TYPES.image,
    flip: { x: 1, y: 1 },
    offset: { x: 0, y: 0 }
};

module.exports = {
    TYPES,
    DEFAULT,
    DEFAULT_IMAGE,
    IMAGE_QUALITY
};
