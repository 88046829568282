import React from 'react';
import PropTypes from 'prop-types';
import LazyFallback from './LazyFallback';

const SuspenseVF = ({ children }) => {
    return (
        <React.Suspense fallback={<LazyFallback />}>{children}</React.Suspense>
    );
};

SuspenseVF.propTypes = {
    children: PropTypes.node.isRequired
};

export default SuspenseVF;
