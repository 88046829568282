import {
    isIOS,
    isIOS13,
    isIPod13,
    isIPad13,
    isMobile,
    isIPhone13,
    deviceType,
    isMobileOnly,
    isMobileSafari
} from 'react-device-detect';
import ReactGA from 'react-ga4';
import { settingManager } from '../global/ref';
import globalState from '../global/state';
import { DEFAULT } from '../data/layer-info';

const MobileAndTablet = () => {
    return (
        isMobile ||
        isIPod13 ||
        isIOS ||
        isIPad13 ||
        isMobileSafari ||
        isIPhone13 ||
        isIOS13 ||
        deviceType === 'mobile' ||
        deviceType === 'tablet'
    );
};

const MobileOnly = () => {
    return isMobileOnly || deviceType === 'mobile';
};

const validateEmail = (email) => {
    const result = email && email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
    if (!result) {
        return false;
    }
    return Array.isArray(result) && result.length > 0;
};

/**
 * Using fetch to communicate with api
 * @param {string} url - api url
 * @param {FormData} body - body of the request
 * @param {Blob} file - large file (used for upload_file api only)
 * @returns
 */
const getFetch = async (url, body = null, file) => {
    if (!url) return null;

    try {
        let options = {};

        if (body) {
            body.append('VF_API_KEY', process.env.VF_API_KEY);

            if (file) {
                const blobRes = await fetch(file);
                if (blobRes) {
                    const blob = await blobRes.blob();
                    if (blob) {
                        body.append('blob', blob);
                    }
                }
            }

            options = {
                body,
                method: 'POST'
            };
        }

        const response = await fetch(url, options);

        if (response && response.ok) {
            const data = await response.json();
            return data;
        }
    } catch (e) {
        return null;
    }

    return null;
};

const parseParam = (params) => {
    let filtered = decodeURIComponent(params);
    filtered = filtered.replace('?', '');

    if (filtered.indexOf('&&') >= 0) {
        filtered = filtered.split('&&');
    }

    if (filtered.indexOf(',') >= 0) {
        filtered = filtered.split(',');
    }

    const data = {};
    if (Array.isArray(filtered)) {
        for (let i = 0; i < filtered.length; i += 1) {
            const [key, value] = filtered[i].split('=');
            data[key] = value;
        }
    } else {
        const [key, value] = filtered.split('=');
        data[key] = value;
    }
    return data;
};

const commonText = (arr) => {
    if (!arr || !Array.isArray(arr) || arr.length === 0) return null;

    let result = null;
    for (let i = 0; i < arr.length; i += 1) {
        let start = -1;
        let end = -1;
        if (i === 0) {
            result = arr[i].toLocaleLowerCase();
        } else {
            const list = arr[i].split(' ');
            for (let j = 0; j < list.length; j += 1) {
                if (result.indexOf(list[j].toLocaleLowerCase()) >= 0) {
                    if (start < 0) start = j;

                    if (j === list.length - 1) end = list.length;
                } else if (start >= 0) {
                    end = j;
                    break;
                }
            }
            result = list.slice(start, end).join(' ');
            start = -1;
            end = -1;
        }
    }
    return !result || !result.length ? null : result;
};

const saveBlob = (blob, filename) => {
    const link = document.createElement('a');
    link.style.display = 'none';
    document.body.appendChild(link); // Firefox workaround, see #6594
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    link.click();

    // URL.revokeObjectURL( url ); breaks Firefox...
};

/**
 * Update / delete annotation object in the global state
 * @param {object} annotation annotation parameters
 * @param {number} index index of the annotation
 */
const updateAnnotation = (annotation, index) => {
    const { setting } = settingManager;
    setting.annotation.entry = setting.annotation.entry.filter((v) => !!v);

    for (let i = 0; i < setting.annotation.entry.length; i += 1) {
        if (setting.annotation.entry[i].index === index) {
            if (!annotation) {
                delete setting.annotation.entry[i];
                return;
            }

            const { point, text } = annotation;
            if (point) {
                setting.annotation.entry[i].point = {
                    x: point.x,
                    y: point.y,
                    z: point.z
                };
            }

            if (text) {
                setting.annotation.entry[i].text = text;
            }
        }
    }
};

const recordAnalytics = (category, action) => {
    if (!category || !action || !process.env.GA) return;

    const mobile = window.innerWidth < 900;

    const evt = {
        action: `${action}: ${mobile ? 'Mobile' : 'Desktop'}`,
        category: `${category}: ${mobile ? 'Mobile' : 'Desktop'}`
    };

    ReactGA.event(evt);
};

const objectDiffPure = (main, final) => {
    if (!main) return final;
    if (!final) return main;

    if (JSON.stringify(final) === JSON.stringify(main)) {
        return null;
    }

    const result = {};

    if (Array.isArray(main) && Array.isArray(final)) {
        if (main.length !== final.length) {
            return final;
        }

        for (let i = 0; i < final.length; i += 1) {
            if (final[i] !== main[i]) {
                return final;
            }
        }

        return [];
    }

    if (Object.is(main, final)) {
        return undefined;
    }

    if (!final || typeof final !== 'object') {
        return final;
    }

    Object.keys(main || {})
        .concat(Object.keys(final || {}))
        .forEach((key) => {
            if (final[key] !== main[key] && !Object.is(main[key], final[key])) {
                result[key] = final[key];
            }

            if (
                typeof final[key] === 'object' &&
                typeof main[key] === 'object'
            ) {
                const value = objectDiffPure(main[key], final[key]);
                if (value !== undefined) {
                    result[key] = value;
                }
            }
        });
    return result;
};

const eraseEmptyElements = (obj) => {
    const keys = Object.keys(obj);
    for (let i = 0; i < keys.length; i += 1) {
        const key = keys[i];
        if (obj[key] === null || obj[key] === undefined) {
            delete obj[key];
        } else if (typeof obj[key] === 'object') {
            if (keys.length === 0) {
                delete obj[key];
            } else {
                eraseEmptyElements(obj[key]);
            }
        }
    }

    return obj;
};

const objectDiff = (main, final) => {
    let diff = objectDiffPure(main, final);
    diff = eraseEmptyElements(diff);
    return diff || {};
};

const combinePaths = (path1, path2) => {
    if (!path1 && !path2) return null;

    if (!path1) return path2;

    if (!path2) return path1;

    let p1 = path1;
    let p2 = path2;

    if (p1[p1.length - 1] === '/') {
        p1 = p1.slice(0, -1);
    }

    if (p2[0] === '/') {
        p2 = p2.substring(1);
    }

    return `${p1}/${p2}`;
};

const newRange = (x, oldMin, oldMax, newMin, newMax) => {
    const xP = parseFloat(x);
    const oldMinP = parseFloat(oldMin);
    const oldMaxP = parseFloat(oldMax);
    const newMinP = parseFloat(newMin);
    const newMaxP = parseFloat(newMax);
    return oldMaxP === oldMinP
        ? 0
        : ((newMaxP - newMinP) * (xP - oldMinP)) / (oldMaxP - oldMinP) +
              newMinP;
};

const isWebGLAvailable = () => {
    try {
        const canvas = document.createElement('canvas');
        return !!(
            window.WebGLRenderingContext &&
            (canvas.getContext('webgl') ||
                canvas.getContext('experimental-webgl'))
        );
    } catch (e) {
        return false;
    }
};

/**
 * Returns the index in the array with key-value pair
 * @param {array} arr - array to pull index from
 * @param {string} key - key to filter
 * @param {string} value - value to filter
 * @returns int
 */
const getIndexByKey = (arr, key, value) => {
    if (!arr || !Array.isArray(arr)) return -1;

    for (let i = 0; i < arr.length; i += 1) {
        if (arr[i][key] === value) return i;
    }

    return -1;
};

/**
 * Return proper layer for that component
 * @param {boolean} defaultValue - if layer is not found, set up a default one
 * @returns {object} defaultValue - return current layer of the current component
 */
const getLayer = (defaultValue = false) => {
    const { setting } = settingManager;
    const { componentId, layerLevel } = globalState.data;
    const { layers } = setting.component?.[componentId];
    const index = getIndexByKey(layers, 'level', layerLevel);

    if (!layers[index] && defaultValue) {
        layers[index] = { ...DEFAULT };
    }

    return layers[index];
};

export default {
    getFetch,
    newRange,
    saveBlob,
    getLayer,
    commonText,
    parseParam,
    MobileOnly,
    objectDiff,
    combinePaths,
    validateEmail,
    getIndexByKey,
    recordAnalytics,
    MobileAndTablet,
    updateAnnotation,
    isWebGLAvailable
};
