import React, { lazy } from 'react';
import ReactDOM from 'react-dom';
import { Router } from '@reach/router';
import Initialize from './component/Initialize';

import './css/variable.css';
import './css/tilted.css';
import './css/index.css';
import './css/font.css';

const Default = lazy(() =>
    import(/* webpackChunkName: "Default" */ './page/Default')
);
const Contact = lazy(() =>
    import(/* webpackChunkName: "Contact" */ './page/Contact')
);
const Pricing = lazy(() =>
    import(/* webpackChunkName: "Pricing" */ './page/Pricing')
);
const Library = lazy(() =>
    import(/* webpackChunkName: "Library" */ './page/Library')
);
const Privacy = lazy(() =>
    import(/* webpackChunkName: "Privacy" */ './page/Privacy')
);
const Request = lazy(() =>
    import(/* webpackChunkName: "Request" */ './page/Request')
);
const Customize = lazy(() =>
    import(/* webpackChunkName: "Customize" */ './page/Customize')
);
const AccountOrder = lazy(() =>
    import(/* webpackChunkName: "AccountOrder" */ './page/AccountOrder')
);
const Object3D = lazy(() =>
    import(/* webpackChunkName: "Object3D" */ './component/Object3D')
);

const Models = lazy(() =>
    import(/* webpackChunkName: "Models" */ './page/Models')
);
const Invoice = lazy(() =>
    import(/* webpackChunkName: "Invoice" */ './page/Invoice')
);
const Home = lazy(() => import(/* webpackChunkName: "Home" */ './page/Home'));
const Faq = lazy(() => import(/* webpackChunkName: "Faq" */ './page/Faq'));
const Why = lazy(() => import(/* webpackChunkName: "Why" */ './page/Why'));
const Configurator = lazy(() =>
    import(/* webpackChunkName: "Configurator" */ './page/Configurator')
);
const Shopify = lazy(() =>
    import(/* webpackChunkName: "Home" */ './page/Shopify')
);

function RouterVF() {
    return (
        <Initialize>
            <Router style={{ height: '100%', width: '100%' }}>
                <Home path='/' />
                <Contact path='/contact' />
                <Pricing path='/pricing' />
                <Why path='/why-vizframe' />
                <Library path='/3d-products' />
                <Configurator path='/online-3d-configurator' />
                <Shopify path='/shopify-3d-models' />
                <Faq path='/help-center' />
                <Request path='/custom-model-quote' />
                <Privacy path='/privacy-policy' />
                <Invoice path='/invoice/:orderNumber/:quote/:discount/:info' />
                <AccountOrder path='/member-order' />
                <Object3D full path='/product/:settingId' />
                <Customize path='/3d-model/:settingId' />
                <Models path='/3d-models' />
                <Models path='/3d-library' />
                <Default default />
            </Router>
        </Initialize>
    );
}

ReactDOM.render(<RouterVF />, document.getElementById('vizframe'));
